import { createApp } from 'vue'
import LoginPage from './App.vue'

//import { BootstrapVue } from 'bootstrap-vue';
import "bootstrap/dist/css/bootstrap.min.css";

// Make BootstrapVue available throughout your project
//Vue.use(BootstrapVue)

createApp(LoginPage).mount('#login')
