<template>
  <NavButton text="Back" url="/"/>
  <div class="container">
        <div class="row">
            <div class="col">
                <h1>Login</h1>
                <div>
                  <form>
                    <div class="form-group">
                      <p v-if="loginMsg" class="alert alert-success">{{loginMsg}}<font-awesome-icon icon="fa-solid fa-window-close"/></p>
                      <p v-if="loginError" class="alert alert-danger">{{loginError}}<font-awesome-icon icon="fa-solid fa-window-close"/></p>
                      <form id="loginForm" @submit.prevent="login">
                        <label for="username">Username</label>
                        <input required type="text" class="form-control" id="username" aria-describedby="usernameHelp" placeholder="Enter username" v-model="this.username">
                        <label for="password">Password</label>
                        <input required type="password" class="form-control" id="password" placeholder="Password" v-model="this.password">
                        <button type="submit" class="btn btn-primary" :disabled="processing || !username || !password">Submit</button>
                      </form>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
 </template>

<script>
import NavButton from '@/components/NavButton.vue';
export default {
  name: 'LoginPage',
  data: function() {
    return {
      username: '',
      password: '',
      loginError: '',
      loginMsg: '',
      processing: false
    }
  },
  components: {
    NavButton
  },
  methods: {
    login: function() {
      console.log('Login submitted');
      this.processing = true;
      this.loginError = '';
      this.loginMsg = '';

      //Post request to login endpoint (promise)
      fetch('/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: this.username,
          password: this.password
        })
      })
      .catch(error => {
        console.error('Login error:', error);
        this.loginError = error;
        this.processing = false;
      })
      .then(async (data) => {
        console.log('Returned:', data);
        if (data.status !== 302) {
          this.loginError = 'Login failed';
          return;
        }
        else {
          this.loginMsg = 'Success';

          let body = await data.json();
          console.log('Body:', body);

          //Redirect to respond body url
          if (body?.redirectUrl) {
            window.location.href = body.redirectUrl;
          }
          else {
            //window.location.href = '/';
            console.error('No redirect url in response');
          }
        }
      })
      .finally(() => {
        this.processing = false;
      });
      
    }
  }
}
</script>

<style scoped>
    .container {
      padding-top: 30px;
    }

    .col>h1 {
      display: block;
      margin: 0 auto;
      text-align: center;
      color: var(--primary-color);
    }

    p {
      font-size: 1rem;
    }

    .col>div {
      width: 30rem;
      max-width: 90vw;
      background-color: var(--background-color-light);
      padding: 1rem;
      border-radius: 10px;
      margin: 1rem auto;
    }

    .col label {
      color: var(--primary-color);
    }

    .col button {
      margin-top: 10px;
    }
</style>

<style>
  @import '~/src/assets/styles/global.css';
</style>
<style>
  @import '~/src/assets/styles/variables.css';
</style>
